<svelte:options tag="responsi-onboard-progress-bar" />


<script>
    import {session as sessionStore} from '../stores/session';
    import {template as templateStore} from '../stores/template';


    let session = false;
    let template = false;


    sessionStore.subscribe(value => {
        session = value;
    });

    templateStore.subscribe((value) => {
        template = value;
    });

</script>

<style>
    .progress-bar-background {
        background: rgb(132, 185, 76);
        border-radius: 8px;
    }
    .progress-bar {
        background: rgb(62, 144, 51);
        border-radius: 8px;
    }
    .progress-bar {
        text-align:right;
    }
    .progress-bar span.progress-text {
        color:#FFF;
    }
</style>

<div class="progress-bar-container">
    {#if template}
        <div class="progress-bar-background">
            <div class="progress-bar {template.completed_percent <= 50 ? ' pre-half' : ' post-half'}" style="width: {template.completed_percent}%">
                <span class="progress-text">{template.completed_percent}%</span>
            </div>
        </div>
    {/if}
</div>