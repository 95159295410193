<svelte:options tag="responsi-onboard-template" />

<script>
    import {onDestroy} from 'svelte';
    import {session as sessionStore} from '../stores/session';
    import {template as templateStore} from '../stores/template';
    import {courses as coursesStore} from '../stores/courses';
    import CircleProgress from './circle-progress.svelte';


    let session = false;
    let template = false;

    let is_desktop = true;
    let nav_open = true;
    let show_hidden = false;

    let active_lesson_id = false;
    let active_lesson_transitioning = false;
    let _last_template_update = false;

    let onboarding_content_element;

    let has_multiple_courses = false;

    let _coursesStoreCleanup = coursesStore.subscribe(courses => {
        has_multiple_courses = courses.length > 1;
    });

    let _sessionStoreCleanup = sessionStore.subscribe(value => {
        session = value;
    });

    let _templateStoreCleanup = templateStore.subscribe((value) => {
        console.log('TemplatStoreSubscribe received', value);
        if(nav_open && value.active_lesson && value.active_lesson.id !== active_lesson_id ) {
            //active lesson changing and nav_open, trigger nav close
            nav_open = false;
        }
        if(active_lesson_id && value.active_lesson && value.active_lesson.id !== active_lesson_id) {
            _last_template_update = value;
            if(active_lesson_transitioning) {
                //already transitioning
                console.log('already transitioning return');
                return;
            }
            active_lesson_transitioning = 'PHASE_OUT';
            setTimeout(() => {
                active_lesson_transitioning = 'PHASE_IN';
                template = _last_template_update;
                active_lesson_id = _last_template_update.active_lesson.id;
                setTimeout(() => {
                    active_lesson_transitioning = false;
                    template = _last_template_update;
                    active_lesson_id = _last_template_update.active_lesson.id;
                }, 200);
            }, 200);
            console.log('using a timeout to set template');
            return;
        }
        if(value.active_lesson) {
            active_lesson_id = value.active_lesson.id;
        } else {
            active_lesson_id = false;
        }
        console.log('setting template like normal', value);
        template = value;
        show_hidden = templateStore.showHidden();

    });


    onDestroy(() => {
        session = false;
        template = false;
        _coursesStoreCleanup();
        _sessionStoreCleanup();
        _templateStoreCleanup();
    });

    function toggleNav() {
        nav_open = !nav_open;
    }

    function openNav() {
        // if(!nav_open && active_lesson_id) {
        //     let elements = document.getElementsByClassName('responsi-app-container');
        //     console.log('found elements', elements);
        //     for(let element of elements) {
        //         console.log('element found', element);
        //         element.scrollTo(0, 0);
        //     }
        // }
        nav_open = true;
        onboarding_content_element.scrollTop = 0;
    }
    function closeNav() {
        if(!active_lesson_id) {
            //do nothing
            return;
        }
        nav_open = false;
        onboarding_content_element.scrollTop = 0;
    }

    function toggleLesson() {
        //only want to toggle open not close
        if(!nav_open) {
            nav_open = true;
        }
    }

    function closeOnboarding() {
        sessionStore.closeOnboarding();
    }

    function handleSearch(data) {
        if(data.target.value && data.target.value.length >= 1) {
            templateStore.updateSearch(data.target.value);
        } else {
            templateStore.updateSearch(false);
        }
    }

    function showCourses() {
        window.dispatchEvent(new CustomEvent('responsicertification', {detail: {
                rb_type: 'courses.show'
            }
        }));
    }
</script>

<style>
    a.nav-title, a.nav-title:active, a.nav-title:visited, a.nav-title:hover {
        text-decoration: none;
        color:#000;
    }
    a.nav-title {
        cursor:pointer;
        font-size:16px;
        font-weight:normal;
        display:block;
        width: 100%;
    }
    ul.chapter-lessons, ul.sections-container, ul.section-chapters {
        margin:0;
        padding: 0 0;
        list-style:none;
        font-size:14px;
    }
    ul.chapter-lessons {

        font-size:12px;
    }
    ul.chapter-lessons a.nav-title {
        font-size:14px;
    }
    .section-title .nav-title {
        font-size:20px;
        font-weight:900;
        margin-right: 18px;
        display:inline-block;
    }
    .chapter-title .nav-title {
        font-size:16px;
        font-weight:bold;
        margin-right: 18px;
        display:inline-block;
    }
    .lesson-title .nav-title {
        font-size:14px;
        font-weight:normal;
    }
    .collapse-container {
        transition: all .3s ease-in-out;
        overflow: hidden;
    }
    .collapse-container.closed, .collapse-container.hidden {
        line-height:0;
        color:transparent;
        padding: 0;
        margin:0;
        display:none;
    }
    .collapse-item {
        position:relative;
    }
    .collapse-item.closed:hover, .lesson.closed:hover {
        background:#e4e3e3;
    }
    .section.collapse-item:after {
        right:10px;
        top:10px;
        position: absolute;
    }
    .section.collapse-item.open:after {
        width: 14px;
        content: url('https://public-rb-certification-custom-install.s3-us-west-2.amazonaws.com/images/nav-arrow.svg');
        transform: rotate(-90deg);
    }
    .section.collapse-item.closed:after {
        width: 14px;
        content: url('https://public-rb-certification-custom-install.s3-us-west-2.amazonaws.com/images/nav-arrow.svg');
    }
    .collapse-container.open.shown {
        line-height:1.5;
        color:#FFF;
        padding: 0;
        margin:0;
    }
    .chapter {

    }
    .section.hidden, .chapter.hidden, .lesson.hidden {
        display:none;
    }
    .chapter.shown {

    }
    .section-chapters.open .chapter.shown.closed .lesson {
        height: 0;
        display: none;
    }
    .section-chapters.open .chapter.shown .chapter-title {
        padding: 6px 28px 6px 40px;
        border-bottom:1px solid #e4e3e3;
    }
    .section-chapters.open .chapter.shown.open .lesson {
        border-bottom:1px solid #e4e3e3;
    }
    .section-chapters.open .chapter.shown .lesson {
        padding:6px 0 6px 50px;
    }
    .section-chapters.open .chapter.shown.open .lesson.open {
        background: rgb(216, 216, 216);
    }

    .chapter.not-required .nav-title, .lesson.not-required .nav-title {
        font-style:italic;
        color:#262626;
    }

    .onboarding-container {
        background:#FFF;
        padding: 0;
        margin: 0 auto;
        position:relative;
        border:1px solid #EFEFEF;
        height: 100%;
        width: 100%;
    }
    .onboarding-content-area {
        position:relative;
        display: flex;
        max-height: 100%;
    }
    .onboarding-container .navigation-container {
        flex: 1 360px;
        max-width: 360px;
        border-right: 1px solid #e4e3e3;
    }
    .onboarding-container .lesson-container {
        flex: 5;
        overflow:scroll;
    }

    /*.onboarding-container .lesson-container.in-active {*/

    /*}*/
    .lesson-active-container {
        padding:0;
    }

    .navigation-search-container {
        text-align:center;
        padding: 20px 0;
    }
    .search-bar {
        width: 90%;
        background:#FFF;
        border:2px solid #e4e3e3;
        border-radius:8px;
        line-height: 22px;
        font-size: 18px;
        position:relative;

    }
    .navigation-search-container {
        position:relative;
    }
    .navigation-search-container:after {
        content: "\f002";
        font-family: "FontAwesome";
        font-size:18px;
        color:#666666;
        position:absolute;
        right: 25px;
        top: 21px;
    }
    .action-button:active, .action-button:hover, .action-button:focus {
        outline:none;
    }
    .action-button {
        padding: 6px 28px;
        font-size: 14px;
        color:#FFF;
        border: 2px solid #11b41a;
        border-radius: 8px;
        position:relative;
        background:#11b41a;
    }
    .action-button.completed:before {
        font-family: "FontAwesome";
        font-weight: 900;
        content: "\f00c";
        position: absolute;
        left: 6px;
        top: 8px;
        color:#FFF;
    }

    .section-header {
        display:flex;
        padding: 6px;
        border-bottom: 1px solid #e6e2e7;
    }
    .section-header .section-progress {
        flex: 1;
        max-width: 60px;
        min-width: 60px;
    }
    .section-header .section-title {
        flex: 4;
        padding-right:30px;
    }
    .active-lesson {
        transition: all ease-in-out 200ms;
    }
    .active-lesson.PHASE_OUT {
        opacity:0;
    }
    .active-lesson.PHASE_IN {
        opacity:1;
    }
    .active-lesson-title {
        margin: 0;
        padding: 20px 0 0 0;
        font-size: 32px;
        font-weight:900;
    }
    .active-lesson-sub-title {
        font-size: 24px;
        font-weight:900;
        padding:6px 0 30px 0;
        margin:0;
        color:#003768;
    }
    .actions-list {
        margin:0;
        padding:0;
        list-style:none;
    }
    .actions-list .action {
        display:block;
        width: 100%;
        padding: 30px;
        box-sizing:border-box;
    }
    .actions-list .action:nth-child(even) {
        background: #e6e2e7;
    }
    .actions-list .action-title {
        padding: 0 0 20px;
        margin:0;
        font-size: 18px;
        font-weight: 900;
    }
    .active-lesson-content-container {
        padding: 0 30px;
    }

    .lesson.incomplete .completed-icon, .chapter.incomplete .completed-icon {
        display:none;
    }
    .lesson.completed .completed-icon, .chapter.completed .completed-icon {
        display:inline-block;
    }
    .completed-icon {
        width: 20px;
        height: 20px;
        float:left;
        margin: 0 10px 0 -30px
    }
    .lesson.completed .completed-icon {
        width: 14px;
        height: 14px;
        margin: 0 10px 0 -24px;
    }
    .graduation-cap {
        width:36px;
    }
    .lesson-video {
        padding: 20px;
    }
    .lesson-video * {
        max-width:100%;
        max-height: 100%;
        width: 100%;
    }
    .navigation-mobile-bar {
        display:none;
    }
    .buttons-container {
        text-align:center;
        padding: 20px 0;
    }

    .buttons-container .mobile-nav-button {
        background:#FFF;
        border: 2px solid #FFF;
        padding: 6px 14px;
        border-radius: 20px;
        color:#000;
        font-size: 18px;
        margin:0 10px;
        cursor:pointer;
    }
    button, button:active, button:focus {
        outline: none;
    }
    .buttons-container .mobile-nav-button.active {
        border-color:#11b41a;
        color:#11b41a;
    }
    .buttons-container .mobile-nav-button.unavailable {
        color: #d2d1d1;
        border-color:#d2d1d1;
        cursor:default;
    }
    .close-button {
        float:right;
        font-size: 18px;
        font-weight: 900;
        background:#FFF;
        margin: 0;
        padding: 10px 20px;
        border: none;
        cursor: pointer;
    }
    .active-lesson-controls-container {
        display:flex;
        padding: 30px 20px;
    }
    .active-lesson-controls-container .previous-section-control-container {
        flex:1;
        text-align:left;
    }
    .active-lesson-controls-container .next-section-control-container {
        flex:1;
        text-align:right;
    }
    .navigation-button {
        border-color:#11b41a;
        color:#11b41a;
        border-radius: 8px;
        padding: 6px 28px;
        font-size: 14px;
    }

    .back-to-courses {
        position:relative;
        padding-left: 30px;
    }
    .back-to-courses:before {
        left:0;
        top:0;
        position: absolute;
        content: url('https://public-rb-certification-custom-install.s3-us-west-2.amazonaws.com/images/back-arrow.svg');
    }
    .nav-to-courses-container {
        padding: 30px 30px 30px 16px;
    }
    .back-to-courses {
        font-weight: 900;
        font-size: 14px;
        cursor: pointer;
    }

    @media(max-width:1250px) {
        .onboarding-container .navigation-container {
            flex: 1 280px;
            max-width: 280px;
        }
        .lesson-video * {
            max-width:100%;
            max-height: 300px;
            width: 100%;
        }
    }
    @media(max-width:992px) {
        .lesson-active-container .close-button {
            display:none;
        }
        .lesson-video * {
            max-width:100%;
            max-height: 260px;
            width: 100%;
        }
        .navigation-mobile-bar {
            display:block;
            width: 100%;
            min-width: 100%;
            height: 80px;
            border-bottom: 1px solid #e6e2e7;
            background:#FFF;
        }
        .onboarding-content-area {
            display:block;
            position:absolute;
            top:80px;
            left:0;
            right:0;
            bottom:0;
            overflow-x:hidden;
            overflow-y: scroll;
        }
        .navigation-container {
            position: absolute;
            top: 0;
            transition: all ease-in-out 500ms;
            z-index:400;
        }
        .navigation-container.closed {
            left: -1250px;
        }
        .navigation-container.open {
            left: 0;
            right: 0;
        }
        .onboarding-container .navigation-container {
            width: 100%;
            max-width: 100%;
            flex:none;
        }
        .lesson-container {
            position: absolute;
            top: 0;
            transition: all ease-in-out 500ms;
            overflow:hidden;
            z-index:500;
        }
        .lesson-container.closed {
            left: 1250px;

        }
        .lesson-container.open {
            left: 0;
            right: 0;
        }
    }
    @media(max-width:762px) {
        .lesson-active-container .close-button {
            display:none;
        }
        .navigation-mobile-bar {
            display:block;
            position:sticky;
            top:50px;
            z-index: 9999;
        }
        .lesson-video * {
            max-width:100%;
            max-height: 220px;
            width: 100%;
        }
    }

</style>

<div class="onboarding-container">
    {#if template && template.sections}
        <div class="navigation-mobile-bar">
            <button on:click={closeOnboarding} class="close-button">X</button>
            <div class="buttons-container">
                <button on:click={openNav} class="mobile-nav-button {nav_open ? 'active': 'inactive'}">Chapters</button>

                <button on:click={closeNav} class="mobile-nav-button {nav_open ? 'inactive': 'active'} {active_lesson_id ? 'available' : 'unavailable'}">Lesson</button>
            </div>
        </div>
        <div class="onboarding-content-area" bind:this={onboarding_content_element}>
            <div class="navigation-container {nav_open ? 'open': 'closed'}">
                <div class="navigation-search-container">
                    <input type="text" placeholder="search" class="search-bar" on:keyup={data => handleSearch(data)} />
                </div>
                <ul class="sections-container">
                    {#each template.sections as section, si}
                        <li class="section collapse-item{section.is_open ? ' open': ' closed'} {section.hidden? 'hidden': 'shown'}">
                            <div class="section-header">
                                <div class="section-progress">
                                    {#if section.total_actions === section.completed_actions}
                                        <img src="https://public-rb-certification-custom-install.s3-us-west-2.amazonaws.com/images/graduation-cap.svg" class="graduation-cap" />
                                    {/if}
                                    {#if section.total_actions !== section.completed_actions}
                                        <responsi-onboard-circle-progress fontcolor="#000" stroke={3} radius={18} fontsize={8} percent={section.completed_percent} />

                                    {/if}
                                </div>
                                <div class="section-title">
                                    <a class="nav-title" on:click={() => templateStore.toggle('SECTION', si)}>{section.title}</a>
                                    {#if show_hidden && section.hidden_count}
                                        <span class="hidden-indicator showing-hidden" on:click={() => templateStore.toggleShowHidden()}>Hide Irrelevant Chapters</span>
                                    {/if}
                                    {#if !show_hidden && section.hidden_count}
                                        <span class="hidden-indicator showing-hidden" on:click={() => templateStore.toggleShowHidden()}>{section.hidden_count} hidden</span>
                                    {/if}
                                </div>
                            </div>
                            <ul class={"section-chapters collapse-container" + (section.is_open ? ' open': ' closed')}>
                        {#each section.chapters as chapter, ci}
                            <li class={"chapter collapse-item" + (chapter.is_open ? ' open' : ' closed') + ((!show_hidden && !chapter.is_required) || chapter.hidden ? ' hidden': ' shown') + (chapter.total_actions === chapter.completed_actions ? ' completed': ' incomplete') + (chapter.is_required ? ' required' : ' not-required')}>
                            <div class="chapter-title">
                                <img src="https://public-rb-certification-custom-install.s3-us-west-2.amazonaws.com/images/checkmark-icon.svg" class="completed-icon" />
                                <a class="nav-title" on:click={() => templateStore.toggle('CHAPTER', ci)}>{chapter.title}</a>
                            </div>
                            <ul class={"chapter-lessons collapse-container" + (chapter.is_open ? ' open': ' closed')}>
                            {#each chapter.lessons as lesson, li}
                                <li class={"lesson" + (lesson.is_open ? ' open': ' closed') + ((!show_hidden && !lesson.is_required) || lesson.hidden ? ' hidden': ' shown') + (lesson.actions.length === lesson.completed_actions ? ' completed': ' incomplete')+ (lesson.is_required ? ' required' : ' not-required')}>
                                <div class="lesson-title">
                                    <img src="https://public-rb-certification-custom-install.s3-us-west-2.amazonaws.com/images/checkmark-icon.svg" class="completed-icon" />
                                    <a class="nav-title" on:click={() => {templateStore.toggle('LESSON', li); closeNav();}}>{lesson.title}</a>
                                </div>
                                </li>
                            {/each}
                        </ul>
                            </li>
                        {/each}
                            </ul>
                        </li>
                    {/each}
                </ul>
                {#if has_multiple_courses}
                    <div class="nav-to-courses-container">
                        <a class="back-to-courses" on:click={() => {showCourses()}}>Back to all courses</a>
                    </div>
                {/if}
            </div>
            <div class="lesson-container {nav_open ? 'closed': 'open'}">
                <div class="lesson-active-container">
                    <button on:click={closeOnboarding} class="close-button">X</button>
                    {#if template.active_lesson}
                        <div class="active-lesson {active_lesson_transitioning ? active_lesson_transitioning : 'in-active'}">
                            <div class="active-lesson-content-container">
                                <h1 class="active-lesson-title">{template.active_lesson.title}</h1>
                                <p class="active-lesson-sub-title">{template.active_lesson.sub_title}</p>
                                {#if has_multiple_courses}
                                    <div class="active-course-to-courses-container">
                                        <a class="back-to-courses" on:click={() => {showCourses()}}>Back to all courses</a>
                                    </div>
                                {/if}
                                <div class="lesson-video">
                                    {@html template.active_lesson.video_embed}
                                </div>
                                <div class="lesson-summary">
                                    {@html template.active_lesson.summary}
                                </div>
                            </div>
                            {#if template.active_lesson.actions.length !== 0}
                                <ul class="actions-list">
                                    {#each template.active_lesson.actions as action}
                                        <li class="action">
                                            <p class="action-title">{action.title}</p>
                                            <div class="pre-summary">
                                                {@html action.pre_summary}
                                            </div>
                                            <div class="button-container">
                                                <button on:click={() => sessionStore.toggleAction(action.id, template.active_lesson.section_id, template.active_lesson.section_index)} class={"action-button" + (action.has_completed ? ' completed' : ' active')}>{action.button_title}</button>
                                            </div>
                                            <div class="post-summary">
                                                {@html action.post_summary}
                                            </div>
                                        </li>
                                    {/each}
                                </ul>
                            {/if}
                            <div class="active-lesson-controls-container">
                                <div class="previous-section-control-container">
                                    {#if template.active_lesson.has_previous}
                                        <button class="previous-section-control navigation-button active" on:click={() => templateStore.toggle('LESSON', 'PREVIOUS')}>Back</button>
                                    {/if}
                                </div>
                                <div class="next-section-control-container">
                                    {#if template.active_lesson.has_next}
                                        <button class="next-section-control navigation-button active" on:click={() => templateStore.toggle('LESSON', 'NEXT')}>
                                            {#if template.active_lesson.is_last_for_section && template.active_lesson.has_next }
                                                Continue to next section
                                            {:else}
                                                Next
                                            {/if}
                                        </button>
                                    {/if}
                                </div>
                            </div>
                        </div>
                    {/if}
                </div>
            </div>
        </div>
    {/if}
</div>