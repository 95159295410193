import {writable} from "svelte/store";


const API_ENDPOINT = 'https://j0sjnfyo9k.execute-api.us-west-2.amazonaws.com/dev/courses';


const {subscribe, update, set} = writable({});


async function load(token) {

    let response = await fetch(API_ENDPOINT, {
        headers: {
            'authorization': token
        }
    });
    let responseData = await response.json();

    set(responseData.data.courses);
}

export const courses = {
    subscribe,
    load
};