<svelte:options tag="responsi-onboard-progress-circle" />


<script>
    import {session as sessionStore} from '../stores/session';
    import {template as templateStore} from '../stores/template';
    import CircleProgress from './circle-progress.svelte';
    import {onMount, onDestroy} from 'svelte';

    export let radius = 60;
    export let fontcolor = '#FFF';
    export let stroke = 8;
    export let strokecolor = 'rgb(0, 152, 213)';
    export let fontsize = 20;
    export let backgroundstroke = 'rgb(123, 142, 164)';

    let session = false;
    let template = false;



    let _sessionStoreCleanup = sessionStore.subscribe(value => {
        session = value;
    });

    let _templateStoreCleanup = templateStore.subscribe((value) => {
        template = value;
    });

    onDestroy(() => {
        templateStore.cleanup();
        _sessionStoreCleanup();
        _templateStoreCleanup();
    });

    function calcProgressLeft(percent) {
        if(percent <= 50) {
            return '';
        }
        return `transform: rotate(180deg);`;
        // console.log('percent', percent);
        // let calc = Math.round((360 * (template.completed_percent / 100)));
        // console.log('Degree calc', calc)
        // return `{calc}deg`;
    }
    function calcProgressRight(percent) {

        let calc = Math.round((360 * (template.completed_percent / 100)));
        return `transform: rotate(${calc}deg);`;
    }

</script>

<style>
    .progress-circle-container {
        text-align:center;
    }
</style>

<div class="progress-circle-container">
    {#if template}
        <responsi-onboard-circle-progress stroke={stroke} strokecolor={strokecolor} fontsize={fontsize} fontcolor={fontcolor} radius={radius} percent={template.completed_percent ? template.completed_percent : 0} background_stroke={backgroundstroke} />
    {/if}
</div>