import { writable } from 'svelte/store';
import {template} from './template';

const {subscribe, set, update} = writable(false);
let has_loaded_session = false;


const API_ENDPOINT = 'https://j0sjnfyo9k.execute-api.us-west-2.amazonaws.com/dev/session';
let _delayedSave = false;
let token;


function closeOnboarding() {
    window.dispatchEvent(new CustomEvent('responsicertification', {detail: {
            rb_type: 'onboarding.close',
        }
    }));
}

function triggerSessionUpdateEvent(session, changes) {
    //global event for others to plugin to
    window.dispatchEvent(new CustomEvent('responsicertification', {detail: {
            rb_type: 'session.update',
            changes,
            session
        }
    }));
    if(changes) {
        if(_delayedSave) {
            clearTimeout(_delayedSave);
        }
        _delayedSave = setTimeout(() => {
            fetch(API_ENDPOINT, {
                method: 'post',
                body: JSON.stringify(session),
                headers: {
                    'authorization': token
                }
            }).then(response => {

            }).catch(error => {
                console.log('Session save error', error);
            })
        }, 1000);
    }
}

function toggleAction(id, section_id, section_index) {

    let changes = {};
    //check if we have completed this action
    update(session => {
        if(session.completed_actions.hasOwnProperty(id) && session.completed_actions[id].has_completed) {
            //is completed, undo
            changes[id] = {has_completed: false, last_changed: new Date().toISOString(), section_id: section_id, section_index: section_index};
            template.actionIncomplete(id);
        } else {
            changes[id] = {has_completed: true, last_changed: new Date().toISOString(), section_id: section_id, section_index: section_index};
            //notify template of the action completed change
            template.actionCompleted(id);
        }

        let newSession = {...session, completed_actions: {...session.completed_actions, ...changes}};

        triggerSessionUpdateEvent(newSession, {completed_actions: changes});

        return newSession;
    });
}

function updateSession(changes, triggerSave=false) {
    update(session => {
        //Don't want to trigger event since the score did not change
        if(triggerSave) {
            triggerSessionUpdateEvent({...session, ...changes}, changes);
        }
        //return changes
        return {...session, ...changes};
    });

}

function clearSessionCache() {
    has_loaded_session = false;
    template.clearCache();
}

async function loadSession(_token, _tags) {
    if(has_loaded_session) {
        return;
    }
    has_loaded_session = true;
    token = _token;
    let response = await fetch(API_ENDPOINT, {
        headers: {
            'authorization': token
        }
    });
    let responseData = await response.json();

    let newSession = {...responseData.data.session, tags: _tags};
    //update the session store with the session data
    set(newSession);
    console.log('Session loaded, template: ', responseData.data.template);
    template.setTemplate(responseData.data.template, newSession);
    //trigger initial event with current session
    triggerSessionUpdateEvent(newSession, false);
}


export const session = {
    subscribe,
    clearSessionCache,
    closeOnboarding,
    updateSession,
    toggleAction,
    loadSession: (token, tags, cb) => {
        loadSession(token, tags).then(() => {
            if(cb) {
                cb();
            }
        }).catch(console.log);
    },
};