<svelte:options tag="responsi-onboard-circle-progress" />
<script>
    import {beforeUpdate} from 'svelte';


    export let stroke = 0;
    export let strokecolor = 'rgb(0, 152, 213)';
    export let radius = 0;
    export let percent = 0;
    export let fontsize = 0;
    export let fontcolor = '#FFF';
    export let background_stroke = '#EFEFEF';


    let normalizedRadius;
    let _circumference;
    let offset;

    function calcProgress() {
        if(!stroke || !radius || percent === undefined) {
            return;
        }
        normalizedRadius = radius - stroke * 2;
        _circumference = normalizedRadius * 2 * Math.PI;


        offset = _circumference - (percent / 100 * _circumference);
    }

    beforeUpdate(() => {
       calcProgress();
    });


</script>

<style>
    .circle-container {
        position:relative;
    }
    circle {
        transition: stroke-dashoffset 0.35s;
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
    }
</style>

<div class="circle-container">
    <svg
            height={radius * 2}
            width={radius * 2}
    >
        <circle
                stroke={background_stroke}
                r={normalizedRadius}
                cx={radius}
                cy={radius} fill="transparent"
                stroke-dasharray="{_circumference} {_circumference}"
                stroke-width={stroke}
        ></circle>
        <circle
                stroke={strokecolor}
                stroke-dasharray="{_circumference} {_circumference}"
                style="stroke-dashoffset:{offset}"
                stroke-width={stroke}
                fill="transparent"
                r={normalizedRadius}
                cx={radius}
                cy={radius}
        />

        <text fill={fontcolor} font-size="{fontsize}px" text-anchor="middle" x={radius} y={parseInt(radius) + (parseInt(fontsize) / 3)}>{percent}%</text>
    </svg>
</div>
