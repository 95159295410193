<svelte:options tag="responsi-onboard" />

<script>
	import TemplateDisplay from './components/template-display.svelte';
	import ProgressBar from './components/progress-bar.svelte';
	import ProgressCircle from './components/progress-circle.svelte';
	import {onMount, onDestroy} from 'svelte';
	import {session as sessionStore} from './stores/session';
	import {courses as coursesStore} from './stores/courses';


	export let display_type = 'CONTENT';
	export let circleradius = 60;
	export let circlestroke = 8;
	export let circlestrokecolor = 'rgb(0, 152, 213)';
	export let circlefontsize = 20;
	export let circlefontcolor = '#FFF';
	export let circlebackgroundstroke = 'rgb(123, 142, 164)';

	let _cached_token;
	let session = false;
	let is_switching = false;
	let template;
	let _raw_template;
	let has_loaded = false;
	let show_hidden = false;

	let mobile_height = 700;
	let is_mobile = false;

	let _sessionStoreSubscriptionCleanup = sessionStore.subscribe(value => {
		session = value;
	});

	function updateMobileHeight() {
		if(window.innerWidth <= 992) {
			mobile_height = window.innerHeight - 80;
			is_mobile = true;
		} else if(is_mobile) {
			is_mobile = false;
		}
	}

	function handleReload() {
		is_switching = true;
		sessionStore.clearSessionCache();
		ResponsiCertification.getToken((token, tags) => {

			sessionStore.loadSession(token, tags, () => {
				is_switching = false;
			});

		});
	}

	let _reloadCallback;
	onMount(() => {
		ResponsiCertification.getToken((token, tags) => {
			sessionStore.loadSession(token, tags);

			if(display_type === 'CONTENT') {
				//load template list here since we have the token.....
				coursesStore.load(token).then(() => {}).catch(console.log);
			}
		});
		_reloadCallback = () => {
			handleReload();
		};
		ResponsiCertification.onReload(_reloadCallback);
		//updateMobileHeight();
		//window.addEventListener('resize', updateMobileHeight)
	});

	onDestroy(() => {
		ResponsiCertification.removeReload(_reloadCallback);
		_sessionStoreSubscriptionCleanup();
		//TODO force a session save
		//window.removeEventListener('resize', updateMobileHeight)
	});

	// function loadToken() {
	// 	return new Promise((resolve, reject) => {
	// 		gettoken((token) => {
	// 			console.log('token received in App.svelte', token);
	// 			_cached_token = token;
	// 			resolve(token);
	// 		});
	// 	});
	// }

	// async function loadSession() {
	// 	const token = await loadToken();
	// 	console.log('Token Received', token);
	// 	console.log('Loading Session clicked');
	// 	await sessionStore.loadSession(token);
	// 	// let response = await fetch('https://j0sjnfyo9k.execute-api.us-west-2.amazonaws.com/dev/session', {
	// 	// 	headers: {
	// 	// 		'authorization': token
	// 	// 	}
	// 	// });
	// 	// console.log('Session loaded response', response);
	// 	// let responseData = await response.json();
	// 	// console.log('responseData', responseData);
	// 	// console.log('data.data.session', responseData.data.session);
	// 	// console.log('data.data.template', responseData.data.template);
	// 	// session = responseData.data.session;
	// 	// _raw_template = responseData.data.template;
	// 	// configureTemplate(_raw_template, session);
	// 	// has_loaded = true;
	// }

	// function toggleAction(id) {
	// 	let changes = {};
	// 	//check if we have completed this action
	// 	if(session.completed_actions.hasOwnProperty(id) && session.completed_actions[id].has_completed) {
	// 		//is completed, undo
	// 		changes[id] = {has_completed: false, last_changed: new Date().toISOString()};
	// 	} else {
	// 		changes[id] = {has_completed: true, last_changed: new Date().toISOString()};
	// 	}
	// 	updateSession({completed_actions: {...session.completed_actions, ...changes}});
	// }
	//
	// console.log('App has_loaded', has_loaded);
	// console.log('App template', template);
	// console.log('App session', session);
</script>

<style>

	responsi-onboard-template {
		height: 100%;
		width: 100%;
		display:block;
	}
</style>

{#if (!session || is_switching)}
	<p>Loading</p>
{:else}
	{#if display_type === 'CONTENT'}
		<responsi-onboard-template />
	{/if}
	{#if display_type === 'PROGRESS_BAR'}
		<responsi-onboard-progress-bar />
	{/if}
	{#if display_type === 'PROGRESS_CIRCLE'}
		<responsi-onboard-progress-circle radius={circleradius} strokecolor={circlestrokecolor} stroke={circlestroke} fontsize={circlefontsize} fontcolor={circlefontcolor} backgroundstroke={circlebackgroundstroke} />
	{/if}
{/if}


