export {default as App} from './App.svelte';

window.ResponsiCertification = {};
window.ResponsiCertification.setup = function(loadToken) {
    this.loadToken = loadToken;
    //check for queued items
    if(window.ResponsiCertification._tokenQueue.length === 0) {
        //nothing queued, all done
        return;
    }
    window.ResponsiCertification.processQueue();
};

window.ResponsiCertification._cachedToken = false;
window.ResponsiCertification._cachedTags = false;
window.ResponsiCertification._tokenQueue = [];
window.ResponsiCertification._is_loading_token = false;
window.ResponsiCertification._refresh_callbacks = [];


window.ResponsiCertification.getToken = function(callback) {
    window.ResponsiCertification._tokenQueue.push(callback);
    this.processQueue();
};

window.ResponsiCertification._processQueueCache = function() {
    window.ResponsiCertification._tokenQueue.forEach((qcb) => {
        qcb(window.ResponsiCertification._cachedToken, window.ResponsiCertification._cachedTags);
    });
};

window.ResponsiCertification.clearTokenCache = function() {
    window.ResponsiCertification._cachedToken = false;
};

window.ResponsiCertification.reload = function() {
    window.ResponsiCertification._refresh_callbacks.forEach(function(cb) {
        cb();
    });
};

window.ResponsiCertification.onReload = function(cb) {
    window.ResponsiCertification._refresh_callbacks.push(cb);
};

window.ResponsiCertification.removeReload = function(cb) {
    if(window.ResponsiCertification._refresh_callbacks.indexOf(cb) !== -1) {
        window.ResponsiCertification._refresh_callbacks.splice(window.ResponsiCertification._refresh_callbacks.indexOf(cb), 1);
    }
};

window.ResponsiCertification.processQueue = function() {
    if(window.ResponsiCertification._cachedToken) {
        this._processQueueCache();
        return;
    }

    if(window.ResponsiCertification._is_loading_token || !this.loadToken) {
        //already loading do nothing
        return;
    }
    window.ResponsiCertification._is_loading_token = true;
    this.loadToken((token, tags) => {
        window.ResponsiCertification._cachedToken = token;
        window.ResponsiCertification._cachedTags = tags;
        window.ResponsiCertification._is_loading_token = false;
        this._processQueueCache();
    });
};