import { writable } from 'svelte/store';
import {session as SessionStore} from './session';


const {subscribe, update, set} = writable({});

let session = false; //need a copy of the session so we can access the tags, hacky and please fix if you want
let _raw_template = false; //FULL source template from API
let _cached_template = false; //cached with chapters / lessons hidden based on tags
let _searched_cached_template = false; //final cache, holds the search results cached
let _last_searched_term = false; //contains the last searched term that is cached in _search_cached_template
let _template = false; //active template instance with sections / chapters / lessons opened

let current_open_section = false;
let current_open_chapter = false;
let current_open_lesson = false;
let nav_open = false;
let show_hidden = false;
let search_string = false;
let last_action_completed_id = false;
let active_lesson_id = false;
let _should_notify_template_updated = false;


function triggerTemplateUpdateEvent(template) {
    //confirm template and session.install_id match
    if(session.install_id === template.id) {
        if(_should_notify_template_updated) {
            _should_notify_template_updated = false;
            //global event for others to plugin to
            window.dispatchEvent(new CustomEvent('responsicertification', {detail: {
                    rb_type: 'template.update',
                    template
                }
            }));
        }
    } else {

    }
}
let _sessionStoreCleanup;
let _session_is_subscribed = false;
function setTemplate(template, sess) {
    if(_raw_template && _raw_template.id !== template.id) {
        //template change
        current_open_section = false;
        current_open_chapter = false;
        current_open_lesson = false;
    }
    _raw_template = template;
    session = sess;

    if(!active_lesson_id && sess.last_open_lesson_id) {
        active_lesson_id = sess.last_open_lesson_id;
        let {si, ci, li} = getActiveLessonIndexes(_raw_template, active_lesson_id);
        if(si !== false) {
            current_open_section = si;
            current_open_chapter = ci;
            current_open_lesson = li;
        }
    }
    configureTemplate();
    if(!_session_is_subscribed) {
        _session_is_subscribed = true;
        //make sure we do the initial load
        _should_notify_template_updated = true;
        _sessionStoreCleanup = SessionStore.subscribe(updatedSess => {
            session = updatedSess;

            configureTemplate();
            //reset
            last_action_completed_id = false;
        });
    }
}

function toggleShowHidden() {
	show_hidden = !show_hidden;
	configureTemplate();
}

function configureTemplate() {
    if(!_raw_template || (session.install_id && session.install_id !== _raw_template.id)) {
        //does not match, do nothing
        return;
    }
    let tag_list = [];
    _cached_template = JSON.parse(JSON.stringify(_raw_template));
	_cached_template.sections.forEach((section, si) => {
		section.hidden_count = 0;
		section.chapters.forEach((chapter, ci) => {
			chapter.hidden_count = 0;
            chapter.is_required = _checkChapterRequired(chapter);
            if(chapter.required_tags && chapter.required_tags.length) {
                for(let tag of chapter.required_tags) {
                    if(tag_list.indexOf(tag) === -1) {
                        tag_list.push(tag);
                    }
                }
            }
            if(!chapter.is_required) {
                section.hidden_count++;
            }
            chapter.lessons.forEach((lesson, li) => {
                lesson.is_required = _checkChapterRequired(lesson);
                if(lesson.required_tags && lesson.required_tags.length) {
                    for(let tag of lesson.required_tags) {
                        if(tag_list.indexOf(tag) === -1) {
                            tag_list.push(tag);
                        }
                    }
                }
                if(!lesson.is_required) {
                    chapter.hidden_count++;
                }
                lesson.has_previous = !(si === 0 && li ===0 && ci === 0);
                lesson.has_next = _cached_template.sections.length !== (si + 1);
                lesson.is_last_for_section = chapter.lessons.length === (li + 1) && section.chapters.length === (ci + 1);
            });
		});
	});
	//console.log('found tags', tag_list);
    scoreTemplate();

	//mark and update store DONE IN SCORE
    //markTemplateOpens();

}

function toggle(type, index) {
    switch(type) {
        case 'SECTION':
            current_open_section = current_open_section === index ? false : index;
            current_open_chapter = false;
            current_open_lesson = false;
            break;
        case 'CHAPTER':
            current_open_chapter = current_open_chapter === index ? false : index;
            current_open_lesson = false;
            break;
        case 'LESSON':
            let {si, ci, li} = getActiveLessonIndexes(_cached_template, active_lesson_id);
            switch(index) {
                case 'NEXT':

                    current_open_section = si;
                    current_open_chapter = ci;
                    current_open_lesson = li;
                    //check if this is the last lesson
                    if(_cached_template.sections[current_open_section].chapters[current_open_chapter].lessons.length <= (current_open_lesson + 1)) {
                        //last lesson, check for next chapter

                        if(_cached_template.sections[current_open_section].chapters.length <= (current_open_chapter + 1)) {
                            //last chapter, check section

                            if(_cached_template.sections.length <= (current_open_section + 1)) {
                                //nothing to do, at the end
                                //maybe do something in the future?
                                break;
                            }
                            //next section, auto open to first lesson / chapter
                            current_open_section += 1;
                            current_open_chapter = 0;
                            current_open_lesson = 0;
                            break;
                        }
                        current_open_chapter += 1;
                        current_open_lesson = 0;
                        break;
                    }

                    current_open_lesson += 1;
                    break;
                case 'PREVIOUS':
                    current_open_section = si;
                    current_open_chapter = ci;
                    current_open_lesson = li;
                    if(current_open_lesson <= 0) {
                        //previous chapter
                        if(current_open_chapter <= 0) {
                            //previous section
                            if(current_open_section <= 0) {
                                //nothing to do right now, at the begging
                                break;
                            }
                            current_open_section -= 0;
                            current_open_chapter = _cached_template.sections[current_open_section].chapters.length - 1;
                            current_open_lesson  = _cached_template.sections[current_open_section].chapters[current_open_chapter].lessons.length - 1;
                            break;
                        }
                        current_open_chapter -= 1;
                        current_open_lesson  = _cached_template.sections[current_open_section].chapters[current_open_chapter].lessons.length - 1;
                        break;
                    }
                    current_open_lesson -= 1;
                    break;
                default:
                    current_open_lesson = index;
                    break;

            }
            //set the active_lesson_id
            active_lesson_id = _cached_template.sections[current_open_section].chapters[current_open_chapter].lessons[current_open_lesson].id;
            break;
    }
    //close nav
    nav_open = false;

    //TODO any global validation considerations.
    markTemplateOpens();
}

function getActiveLessonIndexes(temp, lesson_id) {
    let si = false;
    let ci = false;
    let li = false;
    if(lesson_id) {
        let active_lesson = false;
        for(let [sindex, section] of temp.sections.entries()) {
            for(let [cindex, chapter] of section.chapters.entries()) {
                for(let [lindex, lesson] of chapter.lessons.entries()) {
                    if(lesson.id === lesson_id) {
                        li = lindex;
                        ci = cindex;
                        si = sindex;
                        active_lesson = lesson;
                        break;
                    }
                }
                if(active_lesson) {
                    break;
                }
            }
            if(active_lesson) {
                break;
            }
        }
    }
    return {si, ci, li};
}

let _nextCallbackSet = false;
function markTemplateOpens() {
    _template = JSON.parse(JSON.stringify(_cached_template));
    _template.active_lesson = false;
    if(active_lesson_id) {
        let active_lesson = false;
        let section_index = 0;
        for(let section of _template.sections) {
            for(let chapter of section.chapters) {
                for(let lesson of chapter.lessons) {
                    if(lesson.id === active_lesson_id) {
                        lesson.is_open = true;
                        active_lesson = {...lesson, section_index: section_index, section_id: section.id};
                        break;
                    }
                }
                if(active_lesson) {
                    break;
                }
            }
            if(active_lesson) {
                break;
            }
            section_index++;
        }
        _template.active_lesson = active_lesson;

        if(_template.active_lesson) {
            if(last_action_completed_id) {
                if(last_action_completed_id) {
                    if(_template.active_lesson.completed_actions === _template.active_lesson.actions.length) {
                        //this lesson is done
                        last_action_completed_id = false;
                        if(_nextCallbackSet) {
                            //already pending
                            return;
                        }
                        _nextCallbackSet = true;
                        setTimeout(() => {
                            _nextCallbackSet = false;
                            toggle('LESSON', 'NEXT');
                        });

                        return;
                    }
                }
                //reset
                last_action_completed_id = false;
            }
            //cache current open lesson to session
            if(session.last_open_lesson_id !== _template.active_lesson.id) {
                //update the session
                SessionStore.updateSession({last_open_lesson_id: _template.active_lesson.id}, true);
            }
        }

    }

    //scoreTemplate(_template);
    if(current_open_section === false) {

        processSearch();
        return;
    }
    _template.sections[current_open_section].is_open = true;
    if(current_open_chapter === false) {
        processSearch();
        return;
    }
    _template.sections[current_open_section].chapters[current_open_chapter].is_open = true;
    if(current_open_lesson === false) {
        processSearch();
        return;
    }

    //console.log('markTemplateOpens', current_open_section, current_open_chapter, current_open_lesson);

    //_template.sections[current_open_section].chapters[current_open_chapter].lessons[current_open_lesson].is_open = true;
    //_template.active_lesson = _template.sections[current_open_section].chapters[current_open_chapter].lessons[current_open_lesson];


    processSearch();
}


function updateSearch(term) {
    if(term) {
        search_string = term.trim();
    } else {
        search_string = false;
    }
    processSearch();
}

function processSearch() {
    triggerTemplateUpdateEvent(_template);
    let searched_template = JSON.parse(JSON.stringify(_template));

    if(search_string && search_string.length >= 1) {
        //need to filter out based on search_string
        // searched_template.sections = searched_template.sections.filter(section => {
        //     section.chapters = section.chapters.filter(chapter => {
        //         chapter.lessons = chapter.lessons.filter(lesson => {
        //             return !(lesson.title.indexOf(search_string) === -1 &&
        //                lesson.summary.indexOf(search_string) === -1);
        //         });
        //         //if chapter does not have a match and no lessons we dont want it
        //         return !(
        //             chapter.title.indexOf(search_string) === -1 &&
        //             chapter.lessons.length === 0
        //         );
        //     });
        //
        //     //section does not have a match or chapters we don't want it
        //     return !(
        //         section.title.indexOf(search_string) === -1 &&
        //         section.chapters.length === 0
        //     );
        // });
        searched_template.sections.forEach(section => {
            section.hidden = false;
            let sectionHasVisibleChapter = false;
            section.chapters.forEach(chapter => {
                chapter.hidden = false;
                let chapterHasVisible = false;
                chapter.lessons.forEach(lesson => {
                    lesson.hidden = (lesson.title.toLowerCase().indexOf(search_string.toLowerCase()) === -1 &&
                        lesson.summary.toLowerCase().indexOf(search_string.toLowerCase()) === -1);
                    if(!lesson.hidden) {
                        chapterHasVisible = true;
                    }
                });
                //if chapter does not have a match and no lessons we dont want it
                chapter.hidden = (
                    chapter.title.toLowerCase().indexOf(search_string.toLowerCase()) === -1 &&
                    !chapterHasVisible
                );
                if(!chapter.hidden) {
                    sectionHasVisibleChapter = true;
                }
            });

            //section does not have a match or chapters we don't want it
            section.hidden = (
                section.title.toLowerCase().indexOf(search_string.toLowerCase()) === -1 &&
                !sectionHasVisibleChapter
            );
        });
    } else {
        //set hidden to false no search
        searched_template.sections.forEach(section => {
            section.hidden = false;
            section.chapters.forEach(chapter => {
                chapter.hidden = false;
                chapter.lessons.forEach(lesson => {
                    lesson.hidden = false;
                });
            });
        });
    }
    set(searched_template);
}

function _checkChapterRequired(chapter) {
    if(chapter.required_tags) {
        if(
            (!chapter.required_tag_type) ||
            chapter.required_tag_type === 'AND'
        ) {
            //check that all tags match
            if(chapter.required_tags.filter(tag => session.tags.indexOf(tag) !== -1).length !== chapter.required_tags.length) {
                //not all tags match
                return false;
            }
        } else {
            if(chapter.required_tags.filter(tag => session.tags.indexOf(tag) !== -1).length === 0) {
                //no matching tags
                return false;
            }
        }
    }
    return true;
}

function scoreTemplate() {
    _cached_template.completed_actions = 0;
    _cached_template.total_actions = 0;
    _cached_template.sections.forEach(section => {
        section.completed_actions = 0;
        section.total_actions = 0;
        section.chapters.forEach(chapter => {
            chapter.completed_actions = 0;
            chapter.total_actions = 0;

            chapter.lessons.forEach(lesson => {
                lesson.completed_actions = 0;

                lesson.completed_actions = lesson.actions.filter(action => {
                    action.has_completed = session.completed_actions.hasOwnProperty(action.id) && session.completed_actions[action.id].has_completed;
                    return action.has_completed;
                }).length;
                if(lesson.is_required) {
                    chapter.total_actions += lesson.actions.length;
                    chapter.completed_actions += lesson.completed_actions;
                }

            });

            if(chapter.is_required) {
                section.total_actions += chapter.total_actions;
                section.completed_actions += chapter.completed_actions;
            }
            section.completed_percent = Math.round((section.completed_actions / section.total_actions) * 100);
        });
        _cached_template.completed_actions += section.completed_actions;
        _cached_template.total_actions += section.total_actions;
        _cached_template.completed_percent = Math.round((_cached_template.completed_actions / _cached_template.total_actions) * 100);
    });

    markTemplateOpens();
}

function showHidden() {
    return show_hidden;
}

function actionCompleted(action_id) {
    last_action_completed_id = action_id;
    _should_notify_template_updated = true;
}

function actionIncomplete(action_id) {
    _should_notify_template_updated = true;
}

function clearCache() {
    current_open_section = false;
    current_open_chapter = false;
    current_open_lesson = false;
    _raw_template = false;
    session = false;
    set(false);
}

export const template = {
    subscribe,
    setTemplate,
    toggle,
    toggleShowHidden,
    updateSearch,
    scoreTemplate,
    showHidden,
    actionCompleted,
    actionIncomplete,
    clearCache,
    cleanup: () => {
       clearCache();
        _session_is_subscribed = false;
        _sessionStoreCleanup();
    }
};